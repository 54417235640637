import React, { useContext, useEffect, useState } from "react";
import { Link } from "gatsby";
import Skeleton from "react-loading-skeleton";
import PageWrapper from "../components/PageWrapper";
import { DataContext } from "../context/DataContext";
import { AuthContext } from "../context/AuthContext";
import notify from "../utils/notify";
import { updateJob } from "../api";

const DashboardJobs = () => {
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const { jobs, getjobsList, getTalentJobsList } = useContext(DataContext);
  const { auth } = useContext(AuthContext);

  const fetchJobs = async (accountId) => {
    if (auth?.talent) {
      await getTalentJobsList(accountId);
    } else {
      await getjobsList(accountId);
    }
    setLoading(false);
  };

  useEffect(() => {
    const accountId = window.localStorage.getItem("account_id");
    fetchJobs(accountId);
    return () => setLoading(true);
  }, []);

  const handleDeleteJob = async (job) => {
    try {
      setSubmitting(true);
      const { id, status } = job;
      const payload = {
        status: status === "archived" ? "pending" : "archived",
      };
      await updateJob(id, "delete", payload);
      setSubmitting(false);
      setLoading(true);
      await getjobsList(auth?.client?.account_id, "client");
      setLoading(false);
      notify("Job updated successfully", "success");
    } catch (error) {
      console.log(error.message);
      notify(error.message);
      setSubmitting(false);
    }
  };

  return (
    <>
      <PageWrapper
        headerConfig={{
          button: "profile",
          isFluid: true,
          bgClass: "bg-default",
          reveal: false,
        }}
      >
        <div className="dashboard-main-container mt-25">
          <div className="container">
            <div className="mb-8 mb-sm-18">
              <div className="row mb-7 align-items-center">
                <div className="col-lg-6 mb-lg-0 mb-4">
                  <h3 className="font-size-6 mb-0">My Jobs</h3>
                </div>
              </div>
              <div className="bg-white p-2 py-sm-7 px-sm-11">
                <div className="row">
                  <div className="col-12 order-2 order-xl-1">
                    <div className="">
                      <div className="table-responsive">
                        <table className="table table-striped mt-3">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Category
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Sub-category
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Location
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Created on
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              >
                                Bids Received
                              </th>
                              <th
                                scope="col"
                                className="pl-4 border-0 font-size-4 font-weight-normal"
                              ></th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              [1, 2, 3, 4, 5].map((index) => (
                                <tr
                                  key={index}
                                  className="border border-color-2"
                                >
                                  <th className="table-y-middle pl-6 border-0 py-7 min-width-px-235">
                                    <Skeleton width={200} height={26} />
                                  </th>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <Skeleton width={150} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <Skeleton width={100} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-125">
                                    <Skeleton width={100} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-155">
                                    <Skeleton width={100} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-205">
                                    <Skeleton width={70} height={26} />
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-80">
                                    <Skeleton width={100} height={26} />
                                  </td>
                                </tr>
                              ))
                            ) : jobs.length > 0 && auth?.client ? (
                              jobs.map((job, index) => (
                                <tr
                                  key={index}
                                  className="border border-color-2"
                                >
                                  <th
                                    scope="row"
                                    className="table-y-middle pl-6 border-0 py-7 min-width-px-235"
                                  >
                                    <div className="">
                                      <Link
                                        to={`/job-details/${job.id}`}
                                        state={{ id: job?.id }}
                                        className={`font-size-4 mb-0 font-weight-normal ${
                                          job?.status === "archived"
                                            ? "text-gray"
                                            : "text-black-2"
                                        }`}
                                      >
                                        {job?.position}
                                      </Link>
                                    </div>
                                  </th>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <h3
                                      className={`font-size-4 font-weight-normal ${
                                        job?.status === "archived"
                                          ? "text-gray"
                                          : "text-black-2"
                                      } mb-0`}
                                    >
                                      {job?.category}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-135">
                                    <h3
                                      className={`font-size-4 font-weight-normal ${
                                        job?.status === "archived"
                                          ? "text-gray"
                                          : "text-black-2"
                                      } mb-0`}
                                    >
                                      {job?.subcategory}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-125">
                                    <h3
                                      className={`font-size-4 font-weight-normal ${
                                        job?.status === "archived"
                                          ? "text-gray"
                                          : "text-black-2"
                                      } mb-0`}
                                    >
                                      {job?.location}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-155">
                                    <h3
                                      className={`font-size-4 font-weight-normal ${
                                        job?.status === "archived"
                                          ? "text-gray"
                                          : "text-black-2"
                                      } mb-0`}
                                    >
                                      {/* {new Date(job?.created.toDate()).toDateString()} */}
                                      {`${new Date(
                                        job?.created.toDate()
                                      ).toLocaleDateString()} ${new Date(
                                        job?.created.toDate()
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}`}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-180">
                                    <h3
                                      className={`font-size-4 font-weight-normal ${
                                        job?.status === "archived"
                                          ? "text-gray"
                                          : "text-black-2"
                                      } mb-0 text-center`}
                                    >
                                      {job?.bids.length}
                                    </h3>
                                  </td>
                                  <td className="table-y-middle py-7 min-width-px-110">
                                    <Link
                                      to={`/job-details/${job.id}`}
                                      state={{ id: job?.id }}
                                      className="text-uppercase font-size-3 text-green focus-reset"
                                      data-toggle="tooltip"
                                      title="Details"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                    {auth?.client ? (
                                      <Link
                                        to={`/job-details-edit/${job.id}`}
                                        state={{ id: job?.id }}
                                        className="text-uppercase font-size-3 text-black-2 focus-reset ml-6"
                                        data-toggle="tooltip"
                                        title="Edit"
                                      >
                                        <i className="fa fa-edit"></i>
                                      </Link>
                                    ) : null}
                                    {auth?.client ? (
                                      <button
                                        type="button"
                                        onClick={() => handleDeleteJob(job)}
                                        className="border-0 bg-transparent text-uppercase font-size-3 text-red-2 focus-reset ml-6"
                                        data-toggle="tooltip"
                                        title={
                                          job?.status === "archived"
                                            ? "Undo Delete"
                                            : "Delete"
                                        }
                                      >
                                        {submitting ? (
                                          "..."
                                        ) : job?.status === "archived" ? (
                                          <i className="fa fa-history"></i>
                                        ) : (
                                          <i className="fa fa-trash-alt"></i>
                                        )}
                                      </button>
                                    ) : null}
                                  </td>
                                </tr>
                              ))
                            ) : jobs.length > 0 ? (
                              jobs.map((job, index) =>
                                job.status !== "archived" ? (
                                  <tr
                                    key={index}
                                    className="border border-color-2"
                                  >
                                    <th
                                      scope="row"
                                      className="table-y-middle pl-6 border-0 py-7 min-width-px-235"
                                    >
                                      <div className="">
                                        <Link
                                          to={`/job-details/${job.id}`}
                                          state={{ id: job?.id }}
                                          className="font-size-4 mb-0 font-weight-normal text-black-2"
                                        >
                                          {job?.position}
                                        </Link>
                                      </div>
                                    </th>
                                    <td className="table-y-middle py-7 min-width-px-135">
                                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                        {job?.category}
                                      </h3>
                                    </td>
                                    <td className="table-y-middle py-7 min-width-px-135">
                                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                        {job?.subcategory}
                                      </h3>
                                    </td>
                                    <td className="table-y-middle py-7 min-width-px-125">
                                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                        {job?.location}
                                      </h3>
                                    </td>
                                    <td className="table-y-middle py-7 min-width-px-155">
                                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                        {new Date(
                                          job?.created.toDate()
                                        ).toDateString()}
                                      </h3>
                                    </td>
                                    <td className="table-y-middle py-7 min-width-px-205">
                                      <h3 className="font-size-4 font-weight-normal text-black-2 mb-0 text-center">
                                        {job?.bids.length}
                                      </h3>
                                    </td>
                                    <td className="table-y-middle py-7 min-width-px-80">
                                      <Link
                                        to={`/job-details/${job.id}`}
                                        state={{ id: job?.id }}
                                        className="text-uppercase font-size-3 text-green focus-reset"
                                        data-toggle="tooltip"
                                        title="Details"
                                      >
                                        <i className="fa fa-eye"></i>
                                      </Link>
                                      {auth?.client ? (
                                        <Link
                                          to={`/job-details-edit/${job.id}`}
                                          state={{ id: job?.id }}
                                          className="text-uppercase font-size-3 text-black-2 focus-reset ml-6"
                                          data-toggle="tooltip"
                                          title="Edit"
                                        >
                                          <i className="fa fa-edit"></i>
                                        </Link>
                                      ) : null}
                                      {auth?.client ? (
                                        <button
                                          type="button"
                                          onClick={() => handleDeleteJob(job)}
                                          className="border-0 bg-transparent text-uppercase font-size-3 text-red-2 focus-reset ml-6"
                                          data-toggle="tooltip"
                                          title="Delete"
                                        >
                                          {submitting ? (
                                            "..."
                                          ) : (
                                            <i className="fa fa-trash-alt"></i>
                                          )}
                                        </button>
                                      ) : null}
                                    </td>
                                  </tr>
                                ) : null
                              )
                            ) : (
                              <tr className="border border-color-2">
                                <td
                                  colSpan={7}
                                  className="pl-6 border-0 py-7 pr-0"
                                >
                                  <h4 className="font-size-4 mb-0 font-weight-normal text-black-2">
                                    No jobs found at the moment
                                  </h4>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default DashboardJobs;
